<template>
  <div class="d-flex">
    <div>
      <el-menu
        :default-active="testType"
        class="el-menu-vertical-demo"
        :collapse="false"
        :router="true"
      >
        <el-menu-item
          index="weekly"
          :route="{
            query: {
              test_type: 'weekly'
            }
          }"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">班級練習</span>
        </el-menu-item>
        <el-menu-item
          index="material"
          :route="{
            query: {
              test_type: 'material'
            }
          }"
        >
          <i class="el-icon-folder-opened"></i>
          <span slot="title">上課文件中的練習</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div style="flex:1;overflow: hidden;" class="pl-4">
      <Heading
        heading="h4"
        content="班級練習"
        class="mb-4"
        v-if="testType === 'weekly'"
      >
        <router-link
          slot="button"
          v-if="roleIsTeacherOrAdmin"
          :to="{
            name: 'sessionClassesEdit',
            params: { id: $route.params.classId },
            query: { edit_test: 1 }
          }"
        >
          <el-button type="primary" size="mini">
            <i class="fa fa-edit"></i>
          </el-button>
        </router-link>
      </Heading>
      <Heading
        heading="h4"
        content="上課文件中的練習"
        class="mb-4"
        v-if="testType === 'material'"
      >
        <router-link
          slot="button"
          v-if="roleIsTeacherOrAdmin"
          :to="{
            name: 'sessionClassesEdit',
            params: { id: $route.params.classId },
            query: { section: 'materials' }
          }"
        >
          <el-button type="primary" size="mini">
            <i class="fa fa-edit"></i>
          </el-button>
        </router-link>
      </Heading>
      <hr />
      <StudentTestScoreTable
        v-loading="!section"
        element-loading-spinner="el-icon-loading"
        :section="section"
        type="homework"
        @getSessions="getSessions"
        :disableEdit="!roleIsTeacherOrAdmin"
        :displayStudentUserIds="displayStudentUserIds"
      />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";
// import StudentTestScoreTable from "@/components/sessionClass/StudentTestScoreTable";
import StudentTestScoreTable from "@/views/sessionClasses/tests/components/StudentTestScoreTable";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {
    StudentTestScoreTable
  },

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      section: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    displayStudentUserIds() {
      if (this.roleIsTeacherOrAdmin) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.$store.getters["user/getProfile"].student_ids
            ? this.$store.getters["user/getProfile"].student_ids
            : [];
        } else {
          return [];
        }
      }
    },
    testType() {
      return this.$route.query.test_type
        ? this.$route.query.test_type
        : "weekly";
    }
  },
  watch: {},

  mounted() {
    this.getSessions();
  },

  methods: {
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId,
        {
          test_type: this.testType
        }
      );
      this.section = session_class;
      this.students = session_class.student_class_tickets;
    }
  }
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
